<template>
  <div class="WorkOnline_ToViewList">
    <div class="stu-module-header">
      <div class="stu-module-title">在线作业</div>
      <el-button @click="back" type="primary">返回</el-button>
    </div>
    <div class="content">
      <el-form :model="form" inline>
        <el-form-item label="函授站">
          <site-select-all @input="siteSelect" />
        </el-form-item>
        <el-form-item label="学生姓名/学号">
          <el-input
            v-model="form.info"
            clearable
            placeholder="学生姓名/学号"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="query" type="primary">查询</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="tableData" :height="450" v-loading="loading">
        <el-table-column label="序号" align="center" width="60">
          <template slot-scope="scope">
            <span>{{ scope.$index + (current - 1) * pageSize + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(col, i) in columns"
          :key="i"
          :prop="col.prop"
          :label="col.label"
          :minWidth="col.width"
          align="center"
        ></el-table-column>
        <el-table-column label="状态" align="center" width="100">
          <template slot-scope="scope">
            <el-button @click="handleStatu(scope.row)" type="text">
              查看
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        background
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { QueryStudentWork } from "@/libs/api/teacher/online";
import SiteSelectAll from "@/components/public/site-select-all.vue";
export default {
  components: { SiteSelectAll },
  name: "workOnline_toViewList",
  data() {
    return {
      form: {
        siteId: "",
        info: "",
      },
      loading: false,
      siteNameOptions: [{ label: "全部", value: "0" }],
      columns: [
        { label: "姓名", prop: "studentName" },
        { label: "学号", prop: "studentNo" },
        { label: "电话", prop: "contactPhone" },
        { label: "提交次数", prop: "answerNum" },
        { label: "得分", prop: "maxScore" },
      ],
      tableData: [],
      total: 0,
      pageSize: 10,
      current: 1,
    };
  },

  activated() {
    this.query();
  },
  methods: {
    back() {
      this.$router.push("/teacher/work");
    },
    siteSelect(val) {
      this.form.siteId = val;
    },
    query() {
      const workSetId = this.$route.params.id;
      const p = {
        page: this.current,
        pageSize: this.pageSize,
        ...this.form,
        workSetId,
      };
      this.loading = true;
      QueryStudentWork(p)
        .then((res) => {
          this.tableData = res.data.list;
          this.total = res.data.total;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    init() {},
    handleStatu(row) {
      if (row) {
        const workSetId = this.$route.params.id;
        this.$router.push({
          path: "/teacher/work/toView/detail",
          query: {
            workSetId,
            studentId: row.id,
          },
        });
      }
    },
    handleCurrentChange(page) {
      if (page) {
        this.current = page;
        this.init();
      }
    },
  },
};
</script>

<style scoped lang="less">
.WorkOnline_ToViewList {
  background: #fff;
  box-shadow: @shadow;
  .stu-module-header {
    padding: 20px;
    border-bottom: 1px solid @borderBottomColor;
  }
  .content {
    padding: 20px;
  }
}
</style>